<template>
    <v-dialog v-model="show_dialog" :persistent="is_persistent" :max-width="max_width">
        <v-card v-if="show_dialog" class="custom_card">
            <v-card-title>
                <span class="headline">{{header}}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <slot name="content"></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

    export default {
        props: {
            show: {type: Boolean, default: false},
            max_width: {type: String, default: '600px'},
            is_persistent: {type: Boolean, default: true},
            header: {type: String},
            is_loading: {type: Boolean},
            action: {type: String},
            submit_button_text: {
                type: String, default: function () {
                    return this.$t('general.save');
                }
            }
        },
        data: self => ({
            show_dialog: self.show
        }),
        watch: {
            show(val) {
                this.show_dialog = val;
            }
        },
        methods: {
            // trigger close dialog event in parent component
            closeDialog() {
                this.$emit('close-dialog');
            },
            // trigger submit dialog event in parent component
            submitDialog() {
                this.$emit('submit-dialog');
            }
        }
    }
</script>