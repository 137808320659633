<template>
    <v-container>
        <v-card class="custom_card">
            <v-card-title>
                <v-row>
                    <v-col cols="4" sm="4" md="4" lg="4">
                        <span class="headline">{{ title }}</span>
                    </v-col>
                    <v-col cols="8" sm="8" md="8" lg="8" align="right">
                        <v-chip color="primary" label efault text-color="white" class="ml-2">
                            {{$t('invoices.subtotal')}}: {{ getSubtotal() }}
                        </v-chip>
                        <v-chip color="primary" label efault text-color="white" class="ml-2">
                            {{$t('invoices.total_vat')}}: {{ getTotalVat() }}
                        </v-chip>
                        <v-chip color="green" label efault text-color="white" class="ml-2">
                            {{$t('invoices.total')}}: {{ getTotal() }}
                        </v-chip>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                <!--General info-->
                <v-row>
                    <v-col cols="5" sm="5" md="5" lg="5">
                        <v-autocomplete outlined dense class="custom_field"
                                        v-model="invoice.client_id"
                                        :items="clients"
                                        :label="$t('clients.client')"
                                        item-text="client_name"
                                        item-value="id"
                                        :error-messages="fieldErrors('client_id')"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" lg="2">
                        <v-menu :close-on-content-click="true" transition="scale-transition" offset-y>
                            <template v-slot:activator="{ on }">
                                <v-text-field class="custom_textfield custom_date_picker"
                                              v-model="computedDateFormatted"
                                              :label="$t('general.date')" append-icon="event" readonly
                                              v-on="on" outlined :rules="field_required_rule"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="invoice.date" no-title
                                           @input="date_picker = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" lg="3">
                        <v-btn color="pink" class="custom_button" dark @click="openAddProductDialog()">
                            <v-icon small size="20">add</v-icon>
                            {{$t('products.product')}}
                        </v-btn>
                        <v-btn color="pink" class="" dark @click="openAddServiceDialog()">
                            <v-icon small size="20">add</v-icon>
                            {{$t('services.service')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <!--Datatable-->
            <ResponsiveDataTable :headers="headers" :items="invoice_items" :loading="loading"
                                 :mobileBreakpoint="mobile_breakpoint">
                <!--Buttons-->
                <template v-slot:buttons="{item}">
                    <div class="actions">
                        <v-icon color="primary" :title="$t('general.delete')" @click="deleteInvoiceItem(item)">
                            delete
                        </v-icon>
                    </div>
                </template>
            </ResponsiveDataTable>

            <v-divider></v-divider>

            <v-card-actions class="elevation-1">
                <v-spacer></v-spacer>
                <v-btn color="red" class="close_button" dark to="/invoice_list">
                    <v-icon color="white">close</v-icon>
                </v-btn>
                <v-btn v-if="canCreateInvoiceNoReceipt" class="submit_button" color="black"
                       :disabled="!invoice_items.length"
                       @click="saveInvoice(true, false)" :title="$t('general.save_only_invoice')">
                    <v-icon color="white">mdi-receipt-text-minus-outline</v-icon>
                </v-btn>
                <v-btn class="submit_button" color="black" :disabled="!invoice_items.length"
                       @click="saveInvoice(false, false)" :title="$t('general.save')">
                    <v-icon color="white">mdi-receipt-text-check-outline</v-icon>
                </v-btn>
                <v-btn class="submit_button" color="black" :disabled="!invoice_items.length"
                       @click="saveInvoice(false, true)" :title="$t('general.save_and_sms')">
                    <v-icon color="white">sms</v-icon>
                </v-btn>
            </v-card-actions>

            <!-- Add Product dialog -->
            <InvoiceItemDialog :show="show_add_product_dialog" max_width="70%" :header="$t('products.create')"
                               @close-dialog="closeAddProductDialog" @submit-dialog="addProductToInvoice">
                <template slot="content">
                    <v-form autocomplete="off" class="white_bg_form">
                        <v-row dense>
                            <v-col cols="1">
                                <v-text-field name="product_quantity" type="number" class="custom_field"
                                              v-model="product_quantity" min="1"
                                              :label="$t('general.quantity')" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="7">
                                <v-autocomplete outlined dense class="custom_field"
                                                name="product" v-model="product"
                                                :items="products"
                                                :label="$t('products.product')"
                                                item-text="product"
                                                item-value="id" return-object @change="product_selected">
                                    <template slot="selection" slot-scope="data">
                                        <!-- HTML that describe how select should render selected items -->
                                        {{ data.item.product }} - {{ data.item.price }} €
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <!-- HTML that describe how select should render items when the select is open -->
                                        {{ data.item.product }} - {{ data.item.price }} €
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field name="product_price" type="number" class="custom_field"
                                              v-model="product_price" min="1"
                                              :label="$t('general.price')" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="2" class="text-right">
                                <v-btn color="black" class="submit_button item_dialog_button" dark
                                       @click="addProductToInvoice(product)">
                                    <v-icon color="white">check</v-icon>
                                </v-btn>
                                <v-btn color="red" class="close_button item_dialog_button" dark
                                       @click="closeAddProductDialog">
                                    <v-icon color="white">close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </template>
            </InvoiceItemDialog>
            <!-- Add Service dialog -->
            <InvoiceItemDialog :show="show_add_service_dialog" max_width="70%" :header="$t('services.create')"
                               @close-dialog="closeAddServiceDialog" @submit-dialog="addServiceToInvoice">
                <template slot="content">
                    <v-form autocomplete="off" class="white_bg_form">
                        <v-row dense>
                            <v-col cols="1">
                                <v-text-field name="service_quantity" type="number" class="custom_field"
                                              v-model="service_quantity" min="1"
                                              :label="$t('general.quantity')" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="7">
                                <v-autocomplete outlined dense class="custom_field"
                                                name="service" v-model="service"
                                                :items="services"
                                                :label="$t('services.service')"
                                                item-text="description"
                                                item-value="id" return-object @change="service_selected">
                                    <template slot="selection" slot-scope="data">
                                        <!-- HTML that describe how select should render selected items -->
                                        {{ data.item.description }} - {{ data.item.price }} €
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <!-- HTML that describe how select should render items when the select is open -->
                                        {{ data.item.description }} - {{ data.item.price }} €
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field name="service_price" type="number" class="custom_field"
                                              v-model="service_price" min="1"
                                              :label="$t('general.price')" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="2" class="text-right">
                                <v-btn color="black" class="submit_button item_dialog_button" dark
                                       @click="addServiceToInvoice(service)">
                                    <v-icon color="white">check</v-icon>
                                </v-btn>
                                <v-btn color="red" class="close_button item_dialog_button" dark
                                       @click="closeAddServiceDialog">
                                    <v-icon color="white">close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </template>
            </InvoiceItemDialog>
        </v-card>
        <v-overlay :opacity="0.9" :value="overlay">
            <v-progress-circular indeterminate size="128">
                Loading...
            </v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
    import InvoiceItemDialog from '../../components/InvoiceItemDialog.vue';
    import {validationMixin} from 'vuelidate';
    import {required} from 'vuelidate/lib/validators';
    import moment from 'moment';
    import ResponsiveDataTable from '../../components/ResponsiveDataTable.vue';


    export default {
        props: {
            title: {type: String},
            items: {type: Array},
            loading: {type: Boolean},
            add_button_title: {type: String},
            delete_text: {type: String},
            delete_confirmation_text: {type: String},
            mobile_breakpoint: {type: Number, default: 600},
            item_id: {
                default: null
            }
        },
        components: {
            InvoiceItemDialog, ResponsiveDataTable
        },
        mixins: [validationMixin],
        validations: {
            invoice: {
                client_id: {required}
            }
        },
        data: self => ({
            clients: [],
            products: [],
            services: [],
            date_picker: false,
            headers: null,

            overlay: true,

            url: 'invoices',
            offsetTop: 0,

            // actions permissions
            canUpdateItem: false,

            // add products
            show_add_product_dialog: false,
            product_quantity: 1,
            product_price: 0,
            product: null,

            // add services
            show_add_service_dialog: false,
            service_quantity: 1,
            service_price: 0,
            service: null,

            // invoice
            invoice: {
                business_id: 1,
                client_id: null,
                date: new Date().toISOString().substr(0, 10),
                subtotal: parseFloat(0),
                total: parseFloat(0),
                total_vat: parseFloat(0),
            },
            invoice_items: [],

            field_required_rule: [
                v => !!v || `${this.$t('validation.required')}`
            ],

            // permissions
            canCreateInvoiceNoReceipt: false
        }),
        computed: {
            computedDateFormatted() {
                return this.invoice.date ? moment(this.invoice.date).format('DD/MM/YYYY') : ''
            },
            isFieldsDisabled() {
                return this.action === 'view';
            },
            isSuperAdmin() {
                return this.$store.state.user_details.roles.map(obj => obj.name).indexOf('superadmin') > -1;
            }
        },
        created() {
            this.headers = this.getHeaders();
        },
        async mounted() {

            // get list of clients categories
            let clients_response = await
                this.$store.dispatch('apiRequest', {
                    method: 'get', url: 'clients/list'
                });
            this.clients = clients_response.data;
            // get list of products
            let products_response = await
                this.$store.dispatch('apiRequest', {
                    method: 'get', url: 'product/index'
                });
            this.products = products_response.data;
            if (this.products.length > 0) {
                this.resetProductDialog();
            }
            // get list of services
            let services_response = await
                this.$store.dispatch('apiRequest', {
                    method: 'get', url: 'service/index'
                });
            this.services = services_response.data;
            if (this.services.length > 0) {
                this.resetServiceDialog();
            }
            this.canCreateInvoiceNoReceipt = await this.$store.dispatch('hasPermission', 'create_invoice_no_receipt');

            // edit item
            if (this.item_id) {
                await this.fetchInvoice();
            }

            this.overlay = false;
        },
        methods: {
            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.invoice[prop].$dirty) return errors;
                !this.$v.invoice[prop].required && errors.push(this.$t('validation.required'));
                return errors;
            },
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('general.quantity')}`, value: 'quantity', sortable: true, width: '10%'},
                    {text: `${this.$t('general.description')}`, value: 'description', sortable: true, width: '60%'},
                    {text: `${this.$t('general.price_no_vat')} €`, value: 'price', sortable: true, width: '10%'},
                    {text: `${this.$t('general.vat')}`, value: 'vat_value', sortable: true, width: '10%'},
                    {
                        text: `${this.$t('datatable.actions')}`,
                        value: 'actions',
                        align: 'right',
                        sortable: false,
                        width: '10%'
                    }
                ];
            },
            // manage invoice products
            openAddProductDialog() {
                this.show_add_product_dialog = true;
            },
            closeAddProductDialog() {
                this.show_add_product_dialog = false;
            },
            product_selected(product) {
                this.product_price = product.price;
            },
            addProductToInvoice(product) {
                let price_with_vat = parseFloat(this.product_quantity * this.product_price).toFixed(2);
                let price_without_vat = this.calculatePercentage(price_with_vat, product.vat_value);
                this.show_add_product_dialog = false;
                this.invoice_items.push({
                    "unique_id": Date.now().toString(36),
                    "quantity": this.product_quantity,
                    "description": product.product,
                    "price": price_without_vat.toFixed(2),
                    "vat_value": (price_with_vat - price_without_vat).toFixed(2)
                });
                this.calculateTotal();
                this.resetProductDialog();
            },
            resetProductDialog() {
//                this.product = this.products[0];
//                this.product_price = this.product.price;
                this.product_quantity = 1
            },
            // manage invoice services
            openAddServiceDialog() {
                this.show_add_service_dialog = true;
            },
            closeAddServiceDialog() {
                this.show_add_service_dialog = false;
            },
            service_selected(service) {
                this.service_price = service.price;
            },
            addServiceToInvoice(service) {
                // price of item based on quantity and item price
                let price_with_vat = parseFloat(this.service_quantity * this.service_price).toFixed(2);
                let price_without_vat = this.calculatePercentage(price_with_vat, service.vat_value);
                this.show_add_service_dialog = false;
                this.invoice_items.push({
                    "unique_id": Date.now().toString(36), // random identifier so it can be delted from DT
                    "quantity": this.service_quantity,
                    "description": service.description,
                    "price": price_without_vat.toFixed(2),
                    "vat_value": (price_with_vat - price_without_vat).toFixed(2)
                });
                this.calculateTotal();
                this.resetServiceDialog();
            },
            resetServiceDialog() {
//                this.service = this.services[0];
//                this.service_price = this.service.price;
                this.service_quantity = 1;
            },
            deleteInvoiceItem(item) {
                this.invoice_items = this.invoice_items.filter(obj => obj.unique_id !== item.item.unique_id);
                this.calculateTotal();
            },
            calculatePercentage(price, percent) {
                return (price / ((percent / 100) + 1));
            },
            calculateTotal() {
                this.invoice.subtotal = 0;
                this.invoice.total_vat = 0;
                this.invoice.total = 0;
                if (this.invoice_items.length > 0) {
                    this.invoice.subtotal += this.invoice_items.map(item => parseFloat(item.price)).reduce((acc, item) => acc + item);
                    this.invoice.total_vat += this.invoice_items.map(item => parseFloat(item.vat_value)).reduce((acc, item) => acc + item);
                    this.invoice.total = this.invoice.subtotal + this.invoice.total_vat;
                }
            },
            getTotal() {
                return parseFloat(this.invoice.total).toFixed(2);
            },
            getSubtotal() {
                return parseFloat(this.invoice.subtotal).toFixed(2);
            },
            getTotalVat() {
                return parseFloat(this.invoice.total_vat).toFixed(2);
            },
            async saveInvoice(only_invoice, send_sms) {

                this.$v.$touch();
                // login data are valid
                if (!this.$v.$invalid) {

                    let response = null;
                    let data = {
                        invoice: this.invoice,
                        invoice_items: this.invoice_items,
                        only_invoice: only_invoice,
                        send_sms: send_sms
                    };

                    if (this.item_id) {
                        //update invoice
                        response = await this.$store.dispatch('sendData', {
                            method: 'patch', url: `${this.url}/${this.item_id}`, data: data
                        });
                    } else {
                        // create invoice
                        response = await this.$store.dispatch('sendData', {
                            method: 'post', url: this.url, data: data
                        });
                    }

                    // After successful action, redirect to view work hours
                    if (response && response.status === 200) {
                        this.$router.push('/invoice_list/' + response.data.invoice_id);
                    }
                } else {
                    console.log('Invalid');
                }
            },
            // Get work hours from API
            async fetchInvoice() {
                let response = await this.$store.dispatch('apiRequest', {
                    method: 'get', url: `${this.url}/${this.item_id}`
                });

                if (response.data) {
                    this.invoice.client_id = response.data.client_id;
                    this.invoice.date = new Date(response.data.date).toISOString().substr(0, 10);
                    this.invoice.subtotal = response.data.subtotal;
                    this.invoice.total = response.data.total;
                    this.invoice.total_vat = response.data.total_vat;
                    this.invoice_items = response.data.invoice_items;
                }

                this.overlay = false;
            }
        }
    }
</script>
